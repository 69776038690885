"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admin = require("@/api/admin");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _cookie = require("@/utils/cookie");
var _common_utils = require("@/utils/common_utils");
var _date = _interopRequireDefault(require("@/utils/date"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
var _default = {
  name: 'RemittanceDetailList',
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      id: null,
      remittance_record_no: null,
      ServiceUrl: process.env.VUE_APP_BASE_API,
      distributor_name: '',
      listLoading: true,
      token: (0, _cookie.getCookie)('token'),
      remittance: {
        total_amount: 0,
        total_num: 0,
        success_num: 0,
        fail_num: 0
      },
      biz_options: [{
        value: 10,
        label: 'PC端'
      }, {
        value: 20,
        label: 'App'
      }, {
        value: 30,
        label: '联机宝'
      }],
      statusOptions: [{
        value: 'SETTLE',
        label: '已结算'
      }, {
        value: 'AUDITED',
        label: '待打款'
      }, {
        value: 'PAY_FAIL',
        label: '打款失败'
      }],
      status: '',
      biz_line: '',
      // apply_at: [new Date(), new Date()],
      apply_at: null,
      heads: [],
      list: [],
      total: 0,
      currentPage: 1,
      currentPageSize: 10,
      createPayOrder: false
    };
  },
  created: function created() {
    this.id = (0, _common_utils.getQueryVariable)('id');
    this.remittance_record_no = (0, _common_utils.getQueryVariable)('no');
    this.getRemittance();
    this.handleFilter();
  },
  methods: {
    urlParams: function urlParams() {
      var result = [];
      result.push('remittance_record_no=' + this.remittance_record_no);
      if (this.token) {
        result.push('token=' + this.token);
      }
      if (this.status) {
        result.push('status=' + this.status);
      }
      if (this.distributor_name) {
        result.push('distributor_name=' + this.distributor_name);
      }
      if (this.biz_line) {
        result.push('biz_line=' + this.biz_line);
      }
      if (result) {
        return result.join('&');
      } else {
        return '';
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.currentPageSize = val;
      this.handleFilter();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.handleFilter();
    },
    getRemittance: function getRemittance() {
      var _this = this;
      (0, _admin.getRemittanceRecordDetail)(this.id).then(function (response) {
        _this.remittance = response;
      });
    },
    handleFilter: function handleFilter() {
      var _this2 = this;
      this.listLoading = true;
      var queryData = {
        remittance_record_no: this.remittance_record_no,
        page: this.currentPage,
        limit: this.currentPageSize,
        status: this.status,
        biz_line: this.biz_line,
        distributor_name: this.distributor_name
      };
      (0, _admin.getRemittanceDetailRecord)(queryData).then(function (response) {
        _this2.list = response.results;
        _this2.total = response.count;
        _this2.heads = response.heads;
        _this2.listLoading = false;
      });
    },
    settle: function settle(record_id, settle_amount) {
      var _this3 = this;
      this.$confirm('提交后不可撤回和修改，确定提交吗？', '结算 ' + settle_amount + ' 元', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var currentDate = (0, _date.default)(new Date(), 'yyyy-MM-dd HH:mm:ss');
        (0, _admin.distributorSettleUpdate)(record_id, {
          status: 'SETTLE',
          audit_at: currentDate,
          actual_amount: settle_amount
        }).then(function (response) {
          _this3.$message({
            type: 'success',
            message: '结算成功!'
          });
          _this3.handleFilter();
        }).catch(function (response) {
          _this3.$message({
            type: 'error',
            message: '结算失败'
          });
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消结算'
        });
      });
    }
  }
};
exports.default = _default;