"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
var _default = {
  name: 'App',
  created: function created() {
    var _this = this;
    (0, _user.getCommonConfig)().then(function (res) {
      _this.$store.dispatch('app/setCommonConfig', res);
    });
  }
};
exports.default = _default;