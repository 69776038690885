var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          staticStyle: { "padding-bottom": "100px" },
          attrs: {
            model: _vm.loginForm,
            rules:
              _vm.loginType === "account"
                ? _vm.loginAccountRules
                : _vm.loginPhoneRules,
            "auto-complete": "on",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("奇游分销系统")]),
          ]),
          _vm._v(" "),
          _vm.loginType === "phone"
            ? _c(
                "el-form-item",
                { attrs: { prop: "phone" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "phone",
                    attrs: {
                      placeholder: "请输入手机号码",
                      name: "phone",
                      oninput:
                        "value=value.replace(/[^\\d]/g,'');if(value.length > 11)value = value.slice(0, 11)",
                      tabindex: "1",
                      "auto-complete": "on",
                    },
                    model: {
                      value: _vm.loginForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "phone", $$v)
                      },
                      expression: "loginForm.phone",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loginType === "phone"
            ? _c(
                "el-form-item",
                { attrs: { prop: "code" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "code",
                    attrs: {
                      placeholder: "请输入验证码",
                      name: "code",
                      maxlength: "8",
                      minlength: "4",
                      type: "text",
                      "auto-complete": "on",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.loginForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "code", $$v)
                      },
                      expression: "loginForm.code",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-code", on: { click: _vm.sendCode } },
                    [
                      _c(
                        "el-button",
                        { attrs: { id: "msgBtn", type: "primary" } },
                        [_vm._v(_vm._s(_vm.sendContent))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loginType === "account"
            ? _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      placeholder: "账号",
                      name: "username",
                      type: "text",
                      tabindex: "1",
                      "auto-complete": "on",
                    },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loginType === "account"
            ? _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    key: _vm.passwordType,
                    ref: "password",
                    attrs: {
                      type: _vm.passwordType,
                      placeholder: "密码",
                      name: "password",
                      tabindex: "2",
                      "auto-complete": "on",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class":
                            _vm.passwordType === "password"
                              ? "eye"
                              : "eye-open",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { align: "center" },
            },
            [
              _vm.loginType === "phone"
                ? _c("span", { staticStyle: { color: "#20a0ff" } }, [
                    _vm._v("未注册手机号将创建新账号"),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                },
              },
            },
            [_vm._v("登录\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { type: "text" },
              on: { click: _vm.changeLoginType },
            },
            [_vm._v(_vm._s(_vm.btnName))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c(
            "el-link",
            {
              staticStyle: { "margin-right": "50px" },
              attrs: {
                type: "primary",
                href: "https://www.qiyou.cn/specials/join_junyun",
                target: "_blank",
              },
            },
            [_vm._v("关于我们\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-link",
            {
              attrs: {
                type: "primary",
                href: "https://www.qiyou.cn/main/notice/1369",
                target: "_blank",
              },
            },
            [_vm._v("商务合作")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("el-link", { attrs: { type: "primary", disabled: "" } }, [
            _vm._v("©2014-2023 www.qiyou.cn 版权所有"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }