"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (value === undefined || value === '' || !(0, _validate.validUsername)(value)) {
        callback(new Error('Please enter the correct user name'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value === undefined || value === '' || value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    var validatePhone = function validatePhone(rule, value, callback) {
      var reg = /^1d{10}$/;
      if (value === undefined || reg.test(value)) {
        callback(new Error('手机号格式不正确'));
      } else {
        callback();
      }
    };
    var validateCode = function validateCode(rule, value, callback) {
      if (value === undefined || value.length != 5) {
        callback(new Error('请输入5位验证码'));
      } else {
        callback();
      }
    };
    return {
      loginType: 'phone',
      btnName: '账号密码登录',
      loginForm: {
        username: '',
        password: ''
      },
      loginAccountRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loginPhoneRules: {
        phone: [{
          required: true,
          trigger: 'blur',
          validator: validatePhone
        }],
        code: [{
          required: true,
          trigger: 'blur',
          validator: validateCode
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      sendContent: '获取验证码',
      smsWait: 0,
      sendClass: false
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    sendCode: function sendCode() {
      var _this = this;
      if (this.smsWait !== 0) return false;
      if (!this.loginForm.phone) {
        this.$message({
          type: 'warning',
          message: '请填写正确的手机号码'
        });
        return;
      } else {
        var reg = /^1[3-9][0-9]\d{8}$/;
        if (!reg.test(this.loginForm.phone)) {
          this.$message({
            type: 'warning',
            message: '请填写正确的手机号码'
          });
          return;
        }
      }
      (0, _user.sendSmsCode)({
        phone: this.loginForm.phone
      }).then(function (res) {
        console.log(res);
        if (res.status === 1) {
          _this.$message({
            type: 'warning',
            message: '请勿频繁发送'
          });
          return;
        }
        _this.smsWait = 60;
        _this.waitSms();
        var that = _this;
        _this.smsInterval = setInterval(function () {
          that.waitSms();
        }, 1000);
      });
    },
    waitSms: function waitSms() {
      var that = this;
      that.smsWait--;
      if (that.smsWait === 0) {
        clearInterval(that.smsInterval);
        that.sendContent = '重新获取';
        that.sendClass = false;
      } else {
        that.sendClass = true;
        that.sendContent = that.smsWait + '秒后重新获取';
      }
    },
    changeLoginType: function changeLoginType() {
      this.$refs.loginForm.clearValidate();
      console.log(this.loginType);
      if (this.loginType === 'phone') {
        this.loginType = 'account';
        this.btnName = '手机号码登录';
      } else {
        this.loginType = 'phone';
        this.btnName = '账号密码登录';
      }
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          if (_this3.loginType === 'account') {
            _this3.$store.dispatch('user/login', _this3.loginForm).then(function () {
              _this3.$router.push({
                path: _this3.redirect || '/'
              });
              _this3.loading = false;
            }).catch(function () {
              _this3.loading = false;
            });
          } else {
            _this3.$store.dispatch('user/loginByPhone', _this3.loginForm).then(function () {
              _this3.$router.push({
                path: _this3.redirect || '/'
              });
              _this3.loading = false;
            }).catch(function () {
              _this3.loading = false;
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;