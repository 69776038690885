"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Notice = Notice;
exports.addDistributor = addDistributor;
exports.addPackage = addPackage;
exports.delPackage = delPackage;
exports.distributorSettle = distributorSettle;
exports.distributorSettleRemittance = distributorSettleRemittance;
exports.distributorSettleStatistic = distributorSettleStatistic;
exports.distributorSettleUpdate = distributorSettleUpdate;
exports.editDistributor = editDistributor;
exports.getAllPackage = getAllPackage;
exports.getApplyUpdateCode = getApplyUpdateCode;
exports.getCdkChannel = getCdkChannel;
exports.getDistributor = getDistributor;
exports.getDistributorList = getDistributorList;
exports.getPackage = getPackage;
exports.getPackageConfig = getPackageConfig;
exports.getRemittanceDetailRecord = getRemittanceDetailRecord;
exports.getRemittanceRecord = getRemittanceRecord;
exports.getRemittanceRecordDetail = getRemittanceRecordDetail;
exports.getShareOrder = getShareOrder;
exports.getSharedCard = getSharedCard;
exports.remittancePay = remittancePay;
exports.submitApplyUpdateCode = submitApplyUpdateCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDistributorList(params) {
  return (0, _request.default)({
    url: 'common/v2/distributor_profit/',
    method: 'get',
    params: params
  });
}
function getDistributor(params) {
  return (0, _request.default)({
    url: 'common/distributor/' + params + '/',
    method: 'get',
    params: params
  });
}
function addDistributor(params) {
  return (0, _request.default)({
    url: 'common/distributor/',
    method: 'post',
    data: params
  });
}
function editDistributor(distributor_id, params) {
  return (0, _request.default)({
    url: 'common/distributor/' + distributor_id + '/',
    method: 'patch',
    data: params
  });
}
function getPackageConfig(params) {
  // 获取分销的套餐
  return (0, _request.default)({
    url: 'common/shared_package/',
    method: 'get',
    params: params
  });
}
function getAllPackage() {
  // 获取所有的套餐，用来做添加时候的下拉筛选
  return (0, _request.default)({
    url: 'common/shared_package/get_all_packages/',
    method: 'get'
  });
}
function getPackage() {
  // 用来做下拉筛选
  return (0, _request.default)({
    url: 'common/shared_package/get_packages/',
    method: 'get'
  });
}
function delPackage(params) {
  // 删除分销的套餐
  return (0, _request.default)({
    url: 'common/shared_package/del_package/',
    method: 'get',
    params: params
  });
}
function addPackage(params) {
  // 添加被分销的套餐
  return (0, _request.default)({
    url: 'common/shared_package/add_package/',
    method: 'get',
    params: params
  });
}
function getShareOrder(params) {
  // 分销订单
  return (0, _request.default)({
    url: 'common/shared_order/',
    method: 'get',
    params: params
  });
}
function distributorSettle(params) {
  // 分销商结算列表
  return (0, _request.default)({
    url: 'common/distributor_settle/',
    method: 'get',
    params: params
  });
}
function distributorSettleRemittance(params) {
  // 分销商结算批量打款
  return (0, _request.default)({
    url: 'common/distributor_settle/create_remittance/',
    method: 'get',
    params: params
  });
}
function getSharedCard(params) {
  return (0, _request.default)({
    url: 'common/shared_order/shared_flow_stat/',
    method: 'get',
    params: params
  });
}
function distributorSettleStatistic(params) {
  // 分销商结算列表统计
  return (0, _request.default)({
    url: 'common/distributor_settle/statistical/',
    method: 'get',
    params: params
  });
}
function distributorSettleUpdate(distributor_id, params) {
  // 分销商结算更新
  return (0, _request.default)({
    url: 'common/distributor_settle/' + distributor_id + '/',
    method: 'patch',
    data: params
  });
}
function Notice(params) {
  // 分销商结算列表统计
  return (0, _request.default)({
    url: 'common/notices/new/',
    method: 'get',
    params: params
  });
}
function getCdkChannel(params) {
  // 兑换码渠道商列表
  return (0, _request.default)({
    url: 'common/cdk_channel/',
    method: 'get',
    params: params
  });
}
function getRemittanceRecord(params) {
  // 批量打款单
  return (0, _request.default)({
    url: 'common/remittance_record/',
    method: 'get',
    params: params
  });
}
function getRemittanceRecordDetail(id) {
  // 批量打款单
  return (0, _request.default)({
    url: 'common/remittance_record/' + id + '/',
    method: 'get'
  });
}
function remittancePay(data) {
  // 批量打款
  return (0, _request.default)({
    url: 'common/remittance/pay/',
    method: 'post',
    data: data
  });
}
function getRemittanceDetailRecord(params) {
  // 批量打款单详情
  return (0, _request.default)({
    url: 'common/remittance_detail/',
    method: 'get',
    params: params
  });
}
function getApplyUpdateCode(params) {
  // 专属口令
  return (0, _request.default)({
    url: 'common/distributor_update_code/',
    method: 'get',
    params: params
  });
}
function submitApplyUpdateCode(data) {
  // 专属口令审核
  return (0, _request.default)({
    url: 'common/distributor_update_code/audit/',
    method: 'post',
    data: data
  });
}