"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatQueryParams;
exports.getQueryVariable = getQueryVariable;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
function formatQueryParams(data) {
  var isPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var prefix = isPrefix ? '?' : '';
  var _result = [];
  var _loop = function _loop(key) {
    var value = data[key];
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      return "continue";
    }
    if (value.constructor === Array) {
      value.forEach(function (_value) {
        _result.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(_value));
      });
    } else {
      _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }
  };
  for (var key in data) {
    var _ret = _loop(key);
    if (_ret === "continue") continue;
  }
  return _result.length ? prefix + _result.join('&') : '';
}
function getQueryVariable(variable) {
  var query = window.location.href.split('?', 2);
  if (query.length > 1) {
    query = query[1];
  } else {
    query = '';
  }
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}