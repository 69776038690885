var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "distributor-home clearfix" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "welcome" }, [
        _vm._v("\n      欢迎回归奇游大家庭,"),
        _vm.distributor_info && _vm.distributor_info.distributor_name
          ? _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.distributor_info.distributor_name)),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "left-panel" }, [
      _c("div", { staticClass: "panel2" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("结算")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "view-text",
                on: { click: _vm.getSettleRecordButton },
              },
              [_vm._v("\n          查看历史记录\n        ")]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "结算记录",
                  width: "80%",
                  visible: _vm.recordTableVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.recordTableVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    key: _vm.tableKey,
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.settle_records.list,
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  _vm._l(_vm.settle_records.heads, function (iCol) {
                    return _c("af-table-column", {
                      key: iCol.key,
                      attrs: {
                        label: iCol.value,
                        align: "center",
                        width: iCol.width,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                typeof scope.row[iCol.key] === "object" &&
                                scope.row[iCol.key] !== null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row[iCol.key]["verbose"])
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row[iCol.key])),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content-wrapper" }, [
          _c("div", { staticClass: "balance" }, [
            _c("span", [
              _vm._v(
                "可结算奖励：￥" + _vm._s(_vm.works_money ? _vm.works_money : 0)
              ),
            ]),
            _vm._v(" "),
            _c("button", { on: { click: _vm.applySettle } }, [
              _vm._v("申请结算"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "record" }, [
            _c("div", { staticClass: "tip" }, [_vm._v("最近一次的结算记录")]),
            _vm._v(" "),
            _c("div", { staticClass: "wrapper" }, [
              _c("div", { staticClass: "item" }, [
                _vm._v("申请日期：" + _vm._s(_vm.settle_record.apply_at)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v("审批日期：" + _vm._s(_vm.settle_record.audit_at)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v("状态：" + _vm._s(_vm.settle_record.status.verbose)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v(
                  "\n              金额：￥" +
                    _vm._s(
                      _vm.settle_record.apply_amount
                        ? _vm.settle_record.apply_amount
                        : 0
                    ) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel3" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "title" }, [_vm._v("奖励")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "view-text" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "distributorOrderPoolLayout" } } },
                  [_vm._v("查看奖励明细")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "\n            已结算奖励：￥" +
                  _vm._s(_vm.shared_result.settle_money) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "\n            在途中奖励：￥" +
                  _vm._s(_vm.shared_result.verify_money) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "\n            待结算奖励：￥" +
                  _vm._s(_vm.shared_result.await_money) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "title" }, [_vm._v("贡献")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "view-text" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "distributorMemberPoolLayout" } } },
                  [_vm._v("查看用户明细")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "content-wrapper",
              staticStyle: { "background-color": "#2ACA9F" },
            },
            [
              _c("div", { staticClass: "item" }, [
                _vm._v(
                  "\n            有效注册用户：" +
                    _vm._s(_vm.shared_result.verify_members) +
                    "人\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v(
                  "\n            有效购买用户：" +
                    _vm._s(_vm.shared_result.verify_pay_members) +
                    "人\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v(
                  "\n            有效购买订单：" +
                    _vm._s(_vm.shared_result.verify_orders) +
                    "笔\n          "
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-panel" },
      [
        _c("div", { staticClass: "link" }, [
          _c("div", { staticClass: "select-block" }, [
            _c("div", {
              staticClass: "dot",
              class: { active: _vm.radio === "1" },
              on: {
                click: function ($event) {
                  return _vm.changeRadio("1")
                },
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "dot",
              class: { active: _vm.radio === "2" },
              on: {
                click: function ($event) {
                  return _vm.changeRadio("2")
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.radio == "1"
            ? _c("div", [
                _c("div", { staticClass: "wrapper" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "exclusive-command" }, [
                    _c("div", { staticClass: "inner-content" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("你的专属推广口令"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.limit_times > 0 && _vm.kol_state != -1,
                                expression:
                                  "limit_times > 0 && kol_state != -1",
                              },
                            ],
                            staticClass: "num",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.limit_times >= _vm.used_times
                                    ? _vm.limit_times - _vm.used_times
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("/" + _vm._s(_vm.limit_times))]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm.code != ""
                          ? _c("div", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.code)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.code != ""
                          ? _c("a", {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.code,
                                  expression: "code",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:error",
                                  value: _vm.onError,
                                  expression: "onError",
                                  arg: "error",
                                },
                              ],
                              staticClass: "copy",
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.kol_state === 1,
                                expression: "kol_state === 1",
                              },
                            ],
                            staticClass: "state",
                          },
                          [_vm._v("已有申请中的专属口令，请耐心等待审核")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.kol_state === -1,
                                expression: "kol_state === -1",
                              },
                            ],
                          },
                          [_vm._v("您当前无法申请专属口令")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.kol_state === 0,
                          expression: "kol_state === 0",
                        },
                      ],
                      on: { click: _vm.applyKol },
                    },
                    [_vm._v("\n            申请专属口令\n          ")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.radio == "2"
            ? _c("div", [
                _vm._m(4),
                _vm._v(" "),
                _c("img", {
                  staticClass: "tip-img",
                  attrs: {
                    src: require("../../../assets/distributor-home/link.svg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _vm.referral_link_code && _vm.referral_link_code.link
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy1,
                            expression: "onCopy1",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.referral_link_code.link,
                            expression: "referral_link_code.link",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                      },
                      [_vm._v("\n          复制专属链接\n        ")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.title,
              width: "40%",
              visible: _vm.isApplySettle,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isApplySettle = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "applySettleForm",
                attrs: {
                  model: _vm.applySettleForm,
                  rules: _vm.applySettleRules,
                  "label-position": "right",
                  "label-width": "20%",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "可结算金额" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "50%" },
                      attrs: { autocomplete: "off", disabled: "true" },
                      model: {
                        value: _vm.works_money,
                        callback: function ($$v) {
                          _vm.works_money = $$v
                        },
                        expression: "works_money",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "收款人", prop: "payee_id" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择收款人",
                          disabled: _vm.confirmSettle,
                        },
                        model: {
                          value: _vm.applySettleForm.payee_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.applySettleForm, "payee_id", $$v)
                          },
                          expression: "applySettleForm.payee_id",
                        },
                      },
                      _vm._l(_vm.payeeOptions, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            label:
                              item.alipay_name +
                              "（" +
                              item.alipay_account +
                              "）",
                            value: item.id,
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    !_vm.confirmSettle || _vm.payeeOptions.length < 10
                      ? _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.isAddPayeeForm = true
                              },
                            },
                          },
                          [_vm._v("新增")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.isApplySettle = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addApplySettleForm("applySettleForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "新建收款人信息",
              width: "40%",
              visible: _vm.isAddPayeeForm,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isAddPayeeForm = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "addPayeeForm",
                attrs: {
                  model: _vm.payeeForm,
                  rules: _vm.payeeRules,
                  "label-position": "right",
                  "label-width": "20%",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "支付宝姓名", prop: "alipay_name" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "50%" },
                      attrs: { maxlength: "20", autocomplete: "off" },
                      model: {
                        value: _vm.payeeForm.alipay_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.payeeForm,
                            "alipay_name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "payeeForm.alipay_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "支付宝账号", prop: "alipay_account" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "50%" },
                      attrs: { maxlength: "40", autocomplete: "off" },
                      model: {
                        value: _vm.payeeForm.alipay_account,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.payeeForm,
                            "alipay_account",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "payeeForm.alipay_account",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.isAddPayeeForm = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addPayee("addPayeeForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "申请专属口令",
              width: "60%",
              visible: _vm.isApplyCodeForm,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isApplyCodeForm = $event
              },
              onclose: function ($event) {
                _vm.applyCodeForm.can_use = true
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "applyCodeForm",
                attrs: {
                  model: _vm.applyCodeForm,
                  rules: _vm.applyKolRules,
                  "label-position": "right",
                  "label-width": "20%",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "专属口令", prop: "code" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "50%" },
                      attrs: {
                        id: "exclusiveCommandRef",
                        minlength: "1",
                        maxlength: "16",
                        autocomplete: "off",
                        placeholder: "请输入1-16位推广口令",
                      },
                      on: { input: _vm.checkCode },
                      model: {
                        value: _vm.applyCodeForm.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.applyCodeForm, "code", $$v)
                        },
                        expression: "applyCodeForm.code",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !this.applyCodeForm.can_use &&
                              this.applyCodeForm.code != "",
                            expression:
                              "\n              !this.applyCodeForm.can_use && this.applyCodeForm.code != ''\n            ",
                          },
                        ],
                        staticStyle: { color: "red" },
                      },
                      [_vm._v("该专属口令已存在")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.isApplyCodeForm = false
                        _vm.applyCodeForm.can_use = true
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.applyKolCode("applyCodeForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-bg" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/distributor-home/income.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v("\n            推广口令"),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.qiyou.cn/games/5627.html",
            target: "_blank",
          },
        },
        [_vm._v("推广口令说明")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "command-question" }, [
      _c("div", { staticClass: "question" }, [_vm._v("什么是推广口令？")]),
      _vm._v(" "),
      _c("div", { staticClass: "answer" }, [
        _vm._v(
          "\n              将口令发送给你的朋友，TA能兑换奇游时长，你能参与TA付费后的分成。\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "command-question" }, [
      _c("div", { staticClass: "question" }, [_vm._v("如何使用口令？")]),
      _vm._v(" "),
      _c("div", { staticClass: "answer" }, [
        _vm._v(
          "\n              奇游客户端-使用兑换码，输入口令即可。\n            "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "command-explain" }, [
        _vm._v("口令仅限奇游新用户兑换"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "title" }, [_vm._v("专属链接")]),
      _vm._v(" "),
      _c("div", { staticClass: "tip" }, [
        _vm._v(
          "\n            用户通过您的专属链接注册，即成为您的关联用户，用户下单即可获得奖励。\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }