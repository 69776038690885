import { login as _login, logout as _logout, getInfo as _getInfo, getInfoDetail, loginByPhone as _loginByPhone } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  roles: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        commit('SET_TOKEN', response.auth_token);
        setToken(response.auth_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  loginByPhone: function loginByPhone(_ref2, userInfo) {
    var commit = _ref2.commit;
    var phone = userInfo.phone,
      code = userInfo.code;
    return new Promise(function (resolve, reject) {
      _loginByPhone({
        phone: phone.trim(),
        code: code
      }).then(function (response) {
        commit('SET_TOKEN', response.auth_token);
        setToken(response.auth_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = response;
        getInfoDetail(data.id, state.token).then(function (response1) {
          data.roles = response1.roles;
          var username = data.username;
          commit('SET_ROLES', data.roles);
          commit('SET_NAME', username);
          commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif');
          resolve(data);
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        removeToken();
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};