"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAsyncRoutes = getAsyncRoutes;
var _request = _interopRequireDefault(require("@/utils/request"));
function getAsyncRoutes(data) {
  return (0, _request.default)({
    url: '/rbac/permissions/menu',
    method: 'get',
    data: data
  });
}