"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _date = _interopRequireDefault(require("@/utils/date"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _admin = require("@/api/admin");
var _cookie = require("@/utils/cookie");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      ServiceUrl: process.env.VUE_APP_BASE_API,
      common_config: {},
      registerPrefix: '',
      listLoading: true,
      labelPosition: 'top',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      DialogAction: '',
      biz_options: [{
        value: 10,
        label: 'PC端'
      }, {
        value: 20,
        label: 'App'
      }, {
        value: 30,
        label: '联机宝'
      }],
      cdk_channel_options: [{
        value: 0,
        label: '无'
      }],
      cdk_channel_dict: {
        0: '无'
      },
      package_type_options: {
        MONTH_PACKAGE: '月卡',
        QUARTER_PACKAGE: '季卡',
        YEAR_PACKAGE: '年卡',
        TIMING_PACKAGE: '计时',
        SINGLE_PACKAGE: '专属'
      },
      share_values: {
        'PACKAGE_SALE': '套餐分成',
        'TOTAL_CHARGE': '销售分成',
        'FIRST_CHARGE': '首充分成'
      },
      share_options: [{
        value: 'PACKAGE_SALE',
        label: '套餐分成'
      }, {
        value: 'TOTAL_CHARGE',
        label: '销售分成'
      }, {
        value: 'FIRST_CHARGE',
        label: '首充分成'
      }],
      biz_line: '',
      distributor_info: {
        distributor_id: undefined,
        // distributor_password: undefined,
        distributor_name: undefined,
        source_channel: undefined,
        contact_info: undefined,
        new_day: 30,
        summary: undefined,
        share_type: 'PACKAGE_SALE',
        open_cpa: 0,
        cpa_price: 0,
        cpa_award_timing: 0,
        biz_line: ''
      },
      referral_link_code: {
        distributor_id: undefined,
        referral_code: undefined,
        link: undefined,
        channel: 0,
        cdk_channel: undefined
      },
      distributor_share_total_charge_rule: [{
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }],
      distributor_share_first_charge_rule: [{
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }],
      distributor_share_rule: [{
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'MONTH_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'QUARTER_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'YEAR_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'TIMING_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'SINGLE_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }],
      form: {
        dateFilte: [new Date(), new Date()],
        start_date: null,
        end_date: null,
        distributor_username: '',
        limit: 10,
        page: 0
      },
      heads: [],
      total: 0,
      tableKey: 0,
      list: null
    };
  },
  watch: {
    form: {
      handler: function handler(newName, oldName) {
        if (this.form.dateFilte && this.form.dateFilte[0] !== '') {
          this.form.start_date = (0, _date.default)(this.form.dateFilte[0], 'yyyy-MM-dd');
          this.form.end_date = (0, _date.default)(this.form.dateFilte[1], 'yyyy-MM-dd');
        } else {
          this.form.start_date = '';
          this.form.end_date = '';
        }
      },
      deep: true
    }
  },
  created: function created() {
    this.onSubmit();
  },
  methods: {
    urlParams: function urlParams() {
      var result = [];
      result.push('token=' + (0, _cookie.getCookie)('token'));
      if (this.form.distributor_username) {
        result.push('distributor_username=' + this.form.distributor_username);
      }
      if (this.form.start_date) {
        result.push('start_date=' + this.form.start_date);
        result.push('end_date=' + this.form.end_date);
      }
      if (result) {
        return result.join('&');
      } else {
        return '';
      }
    },
    onSubmit: function onSubmit() {
      // 发送请求获取分销商列表
      // this.form.page = 1
      this.getCdkChannelList();
      this.initCommonConfig();
    },
    onCreate: function onCreate() {
      // 调用模态窗
      this.DialogAction = 'create';
      this.dialogFormVisible = true;
    },
    showDetail: function showDetail(distributor) {
      // 查看分销商请求
      this.DialogAction = 'show';
      this.getDistributorInfo(distributor);
      this.dialogFormVisible = true;
    },
    editDetail: function editDetail(distributor) {
      // 编辑分销商详情
      this.DialogAction = 'edit';
      this.getDistributorInfo(distributor);
      this.dialogFormVisible = true;
    },
    checkChargeData: function checkChargeData() {
      if (this.distributor_info.share_type === 'TOTAL_CHARGE') {
        return this.checkShareChargeData('销售分成', this.distributor_share_total_charge_rule);
      } else if (this.distributor_info.share_type === 'FIRST_CHARGE') {
        return this.checkShareChargeData('首充分成', this.distributor_share_first_charge_rule);
      }
      return true;
    },
    checkShareChargeData: function checkShareChargeData(title, data) {
      var old = null;
      for (var i = 0; i < data.length; i++) {
        var tmp = data[i];
        var reach = parseInt(parseFloat(tmp.reach) * 100);
        var ratio = parseInt(parseFloat(tmp.ratio) * 100);
        if (reach < 0 || ratio < 0) {
          this.$message({
            type: 'warning',
            message: title + '第' + (i + 1) + '行数据有误'
          });
          return false;
        } else if (i === 0 && reach === 0 && ratio === 0) {
          this.$message({
            type: 'warning',
            message: title + '第' + (i + 1) + '行数据有误'
          });
          return false;
        } else if (i > 0 && reach === 0 && ratio > 0 || reach > 0 && ratio === 0) {
          this.$message({
            type: 'warning',
            message: title + '第' + (i + 1) + '行数据有误'
          });
          return false;
        }
        if (old != null && tmp.reach > 0 && old.reach >= tmp.reach) {
          this.$message({
            type: 'warning',
            message: title + '第' + (i + 1) + '行销售额必须大于上一行销售额'
          });
          return false;
        }
        if (ratio > 0) {
          old = tmp;
        }
      }
      return true;
    },
    formSave: function formSave() {
      var _this = this;
      if (!this.checkChargeData()) return;
      // 对话框保存或创建
      var biz_line_ = '';
      for (var i = 0; i < this.biz_options.length; i++) {
        if (this.biz_options[i].value === this.biz_line) {
          biz_line_ = this.biz_options[i];
        }
      }
      for (var j = 0; j < this.distributor_share_rule.length; j++) {
        console.log(this.distributor_share_rule[j].biz_line);
        this.distributor_share_rule[j].biz_line = this.distributor_info.biz_line;
        // this.distributor_share_rule[j].biz_line = { 'key': biz_line_.value, 'verbose': biz_line_.label }
      }

      if (this.referral_link_code.cdk_channel === '') {
        this.referral_link_code.cdk_channel = 0;
      }
      console.log(this.referral_link_code);
      var distributor_charge_share_rule = [];
      var distributor_share_rule = [];
      if (this.distributor_info.share_type === 'TOTAL_CHARGE') {
        distributor_charge_share_rule = this.distributor_share_total_charge_rule;
      } else if (this.distributor_info.share_type === 'FIRST_CHARGE') {
        distributor_charge_share_rule = this.distributor_share_first_charge_rule;
      } else distributor_share_rule = this.distributor_share_rule;
      if (this.DialogAction === 'create') {
        console.log('create');
        var a = {
          distributor_info: this.distributor_info,
          distributor_share_rule: distributor_share_rule,
          referral_detail: this.referral_link_code,
          distributor_charge_share_rule: distributor_charge_share_rule
        };
        (0, _admin.addDistributor)(a).then(function (response) {
          _this.dialogFormVisible = false;
          _this.getList();
        });
      } else if (this.DialogAction === 'edit') {
        var b = {
          distributor_info: this.distributor_info,
          distributor_share_rule: distributor_share_rule,
          referral_detail: this.referral_link_code,
          distributor_charge_share_rule: distributor_charge_share_rule
        };
        (0, _admin.editDistributor)(this.distributor_info.distributor_id, b).then(function (response) {
          _this.dialogFormVisible = false;
          _this.getList();
        });
      } else {
        this.dialogFormVisible = false;
      }
    },
    formCancel: function formCancel() {
      this.dialogFormVisible = false;
    },
    getList: function getList() {
      var _this2 = this;
      if (!this.form.start_date || !this.form.end_date) {
        this.$message({
          type: 'warning',
          message: '请选择时间范围'
        });
        return;
      }
      this.listLoading = true;
      (0, _admin.getDistributorList)(this.form).then(function (response) {
        _this2.list = response.results;
        for (var i in _this2.list) {
          _this2.list[i].cdk_channel;
        }
        _this2.total = response.count;
        _this2.heads = response.heads;
        _this2.listLoading = false;
      });
    },
    getDistributorInfo: function getDistributorInfo(distributor) {
      var _this3 = this;
      (0, _admin.getDistributor)(distributor.distributor_id).then(function (response) {
        _this3.distributor_info = response.distributor_info;
        _this3.distributor_info.biz_line = response.distributor_info.biz_line['key'];
        _this3.distributor_info.share_type = response.distributor_info.share_type['key'];
        _this3.referral_link_code = response.referral_detail;
        if (_this3.distributor_info.share_type === 'TOTAL_CHARGE') {
          _this3.distributor_share_total_charge_rule = response.distributor_charge_share_rule;
          if (_this3.distributor_share_total_charge_rule.length < 5) {
            var l = 5 - _this3.distributor_share_total_charge_rule.length;
            for (var i = 0; i < l; i++) {
              _this3.distributor_share_total_charge_rule.push({
                distributor_id: undefined,
                reach: 0,
                ratio: 0,
                charge_type: 'TOTAL_CHARGE'
              });
            }
          }
        } else if (_this3.distributor_info.share_type === 'FIRST_CHARGE') {
          _this3.distributor_share_first_charge_rule = response.distributor_charge_share_rule;
          if (_this3.distributor_share_first_charge_rule.length < 5) {
            var _l = 5 - _this3.distributor_share_first_charge_rule.length;
            for (var i = 0; i < _l; i++) {
              _this3.distributor_share_first_charge_rule.push({
                distributor_id: undefined,
                reach: 0,
                ratio: 0,
                charge_type: 'FIRST_CHARGE'
              });
            }
          }
        } else {
          _this3.distributor_share_rule = response.distributor_share_rule;
          if (_this3.distributor_share_rule.length === 3) {
            _this3.distributor_share_rule.push({
              distributor_id: undefined,
              biz_line: undefined,
              package_type: 'TIMING_PACKAGE',
              first_charge: undefined,
              first_renew: undefined,
              second_renew: undefined
            });
            _this3.distributor_share_rule.push({
              distributor_id: undefined,
              biz_line: undefined,
              package_type: 'SINGLE_PACKAGE',
              first_charge: undefined,
              first_renew: undefined,
              second_renew: undefined
            });
          }
        }
      });
    },
    getCdkChannelList: function getCdkChannelList() {
      var _this4 = this;
      (0, _admin.getCdkChannel)().then(function (response) {
        _this4.cdk_channel_options = [{
          value: 0,
          label: '无'
        }];
        _this4.cdk_channel_dict = {
          0: '无'
        };
        for (var i in response) {
          _this4.cdk_channel_options.push({
            value: response[i].id,
            label: response[i].channel_name
          });
          _this4.cdk_channel_dict[response[i].id] = response[i].channel_name;
        }
        _this4.getList();
      });
    },
    initCommonConfig: function initCommonConfig() {
      this.common_config = this.$store.state.app.commonConfig;
    },
    resetFields: function resetFields() {
      this.dialogType = '';
      this.share_type = 'PACKAGE_SALE';
      this.distributor_info = {
        distributor_id: undefined,
        distributor_name: undefined,
        source_channel: undefined,
        contact_info: undefined,
        summary: undefined,
        open_cpa: 0
      };
      this.referral_link_code = {
        distributor_id: undefined,
        referral_code: undefined,
        link: undefined
      };
      this.distributor_share_rule = [{
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'MONTH_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'QUARTER_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'YEAR_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'TIMING_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }, {
        distributor_id: undefined,
        biz_line: undefined,
        package_type: 'SINGLE_PACKAGE',
        first_charge: undefined,
        first_renew: undefined,
        second_renew: undefined
      }];
      this.distributor_share_total_charge_rule = [{
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'TOTAL_CHARGE'
      }];
      this.distributor_share_first_charge_rule = [{
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }, {
        distributor_id: undefined,
        reach: 0,
        ratio: 0,
        charge_type: 'FIRST_CHARGE'
      }];
    }
  }
};
exports.default = _default;