var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "calc( 100vh - 60px )", "margin-top": "10px" } },
    [
      _c("el-header", { staticStyle: { "margin-bottom": "10px" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: { placeholder: "分销商名称", clearable: "" },
              model: {
                value: _vm.distributor_name,
                callback: function ($$v) {
                  _vm.distributor_name = $$v
                },
                expression: "distributor_name",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "结算状态" },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              },
              _vm._l(_vm.statusOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "业务线类型" },
                model: {
                  value: _vm.biz_line,
                  callback: function ($$v) {
                    _vm.biz_line = $$v
                  },
                  expression: "biz_line",
                },
              },
              _vm._l(_vm.biz_options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                format: "yyyy-MM-dd",
                "range-separator": "至 ",
                "start-placeholder": "申请开始日期",
                "end-placeholder": "申请结束日期",
              },
              model: {
                value: _vm.apply_at,
                callback: function ($$v) {
                  _vm.apply_at = $$v
                },
                expression: "apply_at",
              },
            }),
            _vm._v(" "),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                format: "yyyy-MM-dd",
                "range-separator": "至 ",
                "start-placeholder": "结算开始日期",
                "end-placeholder": "结算结束日期",
              },
              model: {
                value: _vm.settle_at,
                callback: function ($$v) {
                  _vm.settle_at = $$v
                },
                expression: "settle_at",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("\n          搜索\n        ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    _vm.ServiceUrl +
                    "/common/distributor_settle/export/?" +
                    _vm.urlParams(),
                },
              },
              [
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    staticClass: "filter-item",
                    attrs: { type: "primary", icon: "el-icon-download" },
                  },
                  [_vm._v("下载\n          ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: { type: "primary", disabled: _vm.createPayOrder },
                on: { click: _vm.handlePayOrder },
              },
              [_vm._v("\n          生成打款订单\n        ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.statisticLoading,
                  expression: "statisticLoading",
                },
              ],
              attrs: { gutter: 20, type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 5 } }, [
                _c("div", { staticClass: "grid-content bg-purple-1" }, [
                  _c("div", { staticClass: "grid-content-line" }, [
                    _c("a", [
                      _vm._v("\n                申请结算奖励\n              "),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("a", { staticClass: "number-sta" }, [
                      _vm._v(
                        "\n                ￥ " +
                          _vm._s(_vm.statisticData.apply_amount_sum) +
                          " 元\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("div", { staticClass: "grid-content bg-purple-2" }, [
                  _c("div", { staticClass: "grid-content-line" }, [
                    _c("a", [
                      _vm._v("\n                可结算奖励\n              "),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("a", { staticClass: "number-sta" }, [
                      _vm._v(
                        "\n                ￥ " +
                          _vm._s(_vm.statisticData.works_amount_sum) +
                          " 元\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("div", { staticClass: "grid-content bg-purple-3" }, [
                  _c("div", { staticClass: "grid-content-line" }, [
                    _c("a", [
                      _vm._v("\n                待结算奖励\n              "),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("a", { staticClass: "number-sta" }, [
                      _vm._v(
                        "\n                ￥ " +
                          _vm._s(_vm.statisticData.await_amount_sum) +
                          " 元\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("div", { staticClass: "grid-content bg-purple-4" }, [
                  _c("div", { staticClass: "grid-content-line" }, [
                    _c("a", [
                      _vm._v("\n                在途中奖励\n              "),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("a", { staticClass: "number-sta" }, [
                      _vm._v(
                        "\n                ￥ " +
                          _vm._s(_vm.statisticData.verify_amount_sum) +
                          " 元\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("div", { staticClass: "grid-content bg-purple-5" }, [
                  _c("div", { staticClass: "grid-content-line" }, [
                    _c("a", [
                      _vm._v("\n                已结算奖励\n              "),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("a", { staticClass: "number-sta" }, [
                      _vm._v(
                        "\n                ￥ " +
                          _vm._s(_vm.statisticData.actual_amount_sum) +
                          " 元\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                stripe: "",
                "highlight-current-row": "",
                height: "calc(100vh - 400px)",
              },
            },
            _vm._l(_vm.heads, function (iCol) {
              return _c("af-table-column", {
                key: iCol.key,
                attrs: {
                  fit: "",
                  label: iCol.value,
                  align: "center",
                  width: iCol.width,
                  fixed: iCol.value == "操作" ? "right" : false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          iCol.key === "operation"
                            ? _c(
                                "span",
                                [
                                  scope.row["desc_status"] === "已结算"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { padding: "0px 10px" },
                                          attrs: {
                                            size: "medium",
                                            disabled: "",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.settle(
                                                scope.row["id"],
                                                scope.row["apply_amount"]
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("已结算")]
                                      )
                                    : scope.row["desc_status"] === "审批中"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { padding: "0px 10px" },
                                          attrs: {
                                            size: "medium",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.audit(
                                                scope.row["id"],
                                                scope.row["apply_amount"]
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("审核")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(scope.row[iCol.key]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            staticStyle: { "margin-top": "20px" },
            attrs: {
              align: "left",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.currentPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }