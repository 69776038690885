//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { distributorSettle, distributorSettleRemittance, distributorSettleStatistic, distributorSettleUpdate } from '@/api/admin';
import formatDate from '@/utils/date';
import waves from '@/directive/waves'; // waves directive
import { getCookie } from '@/utils/cookie';
export default {
  name: 'DistributorSettleList',
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      ServiceUrl: process.env.VUE_APP_BASE_API,
      distributor_name: '',
      listLoading: true,
      token: getCookie('token'),
      statisticLoading: true,
      statisticData: {
        apply_amount_sum: 0,
        actual_amount_sum: 9,
        verify_amount_sum: 9,
        await_amount_sum: 9,
        works_amount_sum: 9
      },
      biz_options: [{
        value: 10,
        label: 'PC端'
      }, {
        value: 20,
        label: 'App'
      }, {
        value: 30,
        label: '联机宝'
      }],
      statusOptions: [{
        value: 'SETTLE',
        label: '已结算'
      }, {
        value: 'AUDITED',
        label: '待打款'
      }, {
        value: 'AUDITING',
        label: '审批中'
      }, {
        value: 'PAY_FAIL',
        label: '打款失败'
      }],
      status: '',
      biz_line: '',
      apply_at: null,
      settle_at: null,
      heads: [],
      list: [],
      total: 0,
      currentPage: 1,
      currentPageSize: 10,
      createPayOrder: false
    };
  },
  created: function created() {
    this.handleFilter();
  },
  methods: {
    urlParams: function urlParams() {
      var result = [];
      if (this.token) {
        result.push('token=' + this.token);
      }
      if (this.status) {
        result.push('status=' + this.status);
      }
      if (this.distributor_name) {
        result.push('distributor_name__icontains=' + this.distributor_name);
      }
      if (this.biz_line) {
        result.push('biz_line=' + this.biz_line);
      }
      if (this.apply_at) {
        result.push('apply_at__gte=' + formatDate(this.apply_at[0], 'yyyy-MM-dd 00:00:00'));
        result.push('apply_at__lte=' + formatDate(this.apply_at[1], 'yyyy-MM-dd 23:59:59'));
      }
      if (this.settle_at) {
        result.push('settle_at__gte=' + formatDate(this.settle_at[0], 'yyyy-MM-dd 00:00:00'));
        result.push('settle_at__lte=' + formatDate(this.settle_at[1], 'yyyy-MM-dd 23:59:59'));
      }
      if (result) {
        return result.join('&');
      } else {
        return '';
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.currentPageSize = val;
      this.handleFilter();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.handleFilter();
    },
    handlePayOrder: function handlePayOrder() {
      var _this = this;
      if (this.status !== 'AUDITED') {
        this.$message({
          type: 'warning',
          message: '请先搜索结算状态为"待打款"的订单'
        });
        return;
      }
      if (this.createPayOrder) return;
      this.createPayOrder = true;
      var queryData = {
        status: this.status,
        biz_line: this.biz_line,
        distributor_name__icontains: this.distributor_name
      };
      if (this.apply_at) {
        queryData.apply_at__gte = formatDate(this.apply_at[0], 'yyyy-MM-dd 00:00:00');
        queryData.apply_at__lte = formatDate(this.apply_at[1], 'yyyy-MM-dd 23:23:59');
      }
      if (this.settle_at) {
        queryData.settle_at__gte = formatDate(this.settle_at[0], 'yyyy-MM-dd 00:00:00');
        queryData.settle_at__lte = formatDate(this.settle_at[1], 'yyyy-MM-dd 23:23:59');
      }
      distributorSettleRemittance(queryData).then(function (response) {
        _this.$message({
          type: 'info',
          message: response.msg
        });
      }).finally(function () {
        _this.createPayOrder = false;
      });
    },
    handleFilter: function handleFilter() {
      var _this2 = this;
      this.listLoading = true;
      this.statisticLoading = true;
      var queryData = {
        page: this.currentPage,
        limit: this.currentPageSize,
        apply_at__gte: null,
        apply_at__lte: null,
        settle_at__gte: null,
        settle_at__lte: null,
        status: this.status,
        biz_line: this.biz_line,
        distributor_name__icontains: this.distributor_name
      };
      if (this.apply_at) {
        queryData.apply_at__gte = formatDate(this.apply_at[0], 'yyyy-MM-dd 00:00:00');
        queryData.apply_at__lte = formatDate(this.apply_at[1], 'yyyy-MM-dd 23:23:59');
      }
      if (this.settle_at) {
        queryData.settle_at__gte = formatDate(this.settle_at[0], 'yyyy-MM-dd 00:00:00');
        queryData.settle_at__lte = formatDate(this.settle_at[1], 'yyyy-MM-dd 23:59:59');
      }
      distributorSettle(queryData).then(function (response) {
        _this2.list = response.results;
        _this2.total = response.count;
        _this2.heads = response.heads;
        _this2.listLoading = false;
      });
      distributorSettleStatistic(queryData).then(function (response) {
        _this2.statisticData = response;
        _this2.statisticLoading = false;
      });
    },
    audit: function audit(record_id, settle_amount) {
      var _this3 = this;
      this.$confirm('提交后不可撤回和修改，确定提交吗？', '审核 ' + settle_amount + ' 元', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var currentDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
        console.log(currentDate);
        distributorSettleUpdate(record_id, {
          status: 'AUDITED',
          audit_at: currentDate,
          actual_amount: settle_amount
        }).then(function (response) {
          _this3.$message({
            type: 'success',
            message: '审核成功!'
          });
          _this3.handleFilter();
        }).catch(function (response) {
          _this3.$message({
            type: 'error',
            message: '审核成功'
          });
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    },
    settle: function settle(record_id, settle_amount) {
      var _this4 = this;
      this.$confirm('提交后不可撤回和修改，确定提交吗？', '结算 ' + settle_amount + ' 元', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var currentDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
        console.log(currentDate);
        distributorSettleUpdate(record_id, {
          status: 'SETTLE',
          audit_at: currentDate,
          actual_amount: settle_amount
        }).then(function (response) {
          _this4.$message({
            type: 'success',
            message: '结算成功!'
          });
          _this4.handleFilter();
        }).catch(function (response) {
          _this4.$message({
            type: 'error',
            message: '结算失败'
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消结算'
        });
      });
    }
  }
};