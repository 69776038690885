"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _admin = require("@/api/admin");
var _cookie = require("@/utils/cookie");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _date = _interopRequireDefault(require("@/utils/date"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      loadingList: true,
      loadingStatistic: true,
      token: (0, _cookie.getCookie)('token'),
      form: {
        no: '',
        limit: 10,
        page: 1
      },
      create_at: null,
      pay_at: null,
      status: null,
      shared_card: [],
      heads: [],
      total: 0,
      tableKey: 0,
      list: null,
      statusOptions: [{
        value: 'UNPAY',
        label: '未打款'
      }, {
        value: 'PAY_FAIL',
        label: '打款失败'
      }, {
        value: 'PART_FAIL',
        label: '部分成功'
      }, {
        value: 'PAY',
        label: '已付款'
      }]
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    onSubmit: function onSubmit() {
      this.getList();
    },
    jumpDetail: function jumpDetail(row) {
      this.$router.push({
        path: '/adminRemittances/detail?id=' + row.id + '&no=' + row.no
      });
    },
    pay: function pay(row) {
      var that = this;
      var msg = '本次打款:' + (parseFloat(row.total_amount) - row.paid_amount) + ',打款数量：' + (row.total_num - row.success_num);
      this.$confirm(msg, '打款确认', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        showClose: true,
        type: 'warning'
      }).then(function () {
        that.doPay(row);
      });
    },
    doPay: function doPay(row) {
      var _this = this;
      this.loadingList = true;
      (0, _admin.remittancePay)({
        id: row.id
      }).then(function (response) {
        if (response.status) {
          _this.$message({
            type: 'success',
            message: '打款成功'
          });
          _this.getList();
        } else {
          _this.$message({
            type: 'success',
            message: '打款失败'
          });
        }
        _this.loadingList = false;
      }).finally(function () {
        _this.loadingList = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.loadingList = true;
      this.loadingStatistic = true;
      this.form.pay_at__gte = '';
      this.form.pay_at__lte = '';
      this.form.create_at__gte = '';
      this.form.create_at__lte = '';
      this.form.status = '';
      if (this.create_at) {
        this.form.create_at__gte = (0, _date.default)(this.create_at[0], 'yyyy-MM-dd 00:00:00');
        this.form.create_at__lte = (0, _date.default)(this.create_at[1], 'yyyy-MM-dd 23:23:59');
      }
      if (this.pay_at) {
        this.form.pay_at__gte = (0, _date.default)(this.pay_at[0], 'yyyy-MM-dd 00:00:00');
        this.form.pay_at__lte = (0, _date.default)(this.pay_at[1], 'yyyy-MM-dd 23:23:59');
      }
      if (this.status) this.form.status = this.status;
      (0, _admin.getRemittanceRecord)(this.form).then(function (response) {
        _this2.list = response.results;
        _this2.heads = response.heads;
        _this2.total = response.count;
        _this2.loadingList = false;
      });
    }
  }
};
exports.default = _default;