"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _cookie = require("./cookie");
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  // config.headers['content-type'] = 'application/x-www-form-urlencoded'
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    var token = (0, _auth.getToken)();
    if (token) {
      config.headers['Authorization'] = ' Token ' + token;
      (0, _cookie.setCookie)('token', token, 1);
    }
  }
  config.headers['Product-Line'] = 'QY';
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  return res;
}, function (error) {
  if (error.response) {
    var status = error.response.status;
    if (status === 401) {
      // to re-login
      _elementUi.MessageBox.confirm('登录已过期,是否重新登录', 'Confirm logout', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }
  }
  if (error.response.status === 400) {
    message = error.response.data;
    var msg_ = new Set();
    dealMsg(msg_, message);
    msg_ = Array.from(msg_);
    if (error.response.data.msg) {
      message = error.response.data.msg;
      console.log(message);
    }
    (0, _elementUi.Message)({
      message: msg_.join(' '),
      type: 'error',
      duration: 7 * 1000
    });
  } else {
    console.log(error.response.data); // for debug
    var message = error.response.data;
    if (error.response.data.msg) {
      message = error.response.data.msg;
    }
    (0, _elementUi.Message)({
      message: message,
      type: 'error',
      duration: 7 * 1000
    });
  }
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;
function dealMsg(msg_, message) {
  for (var _i = 0, _Object$values = Object.values(message); _i < _Object$values.length; _i++) {
    var val = _Object$values[_i];
    if (val.constructor === Object || val.constructor === Array) {
      console.log(val);
      dealMsg(msg_, val);
    } else {
      msg_.add(val);
    }
  }
}