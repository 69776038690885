"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCommonConfig = getCommonConfig;
exports.getInfo = getInfo;
exports.getInfoDetail = getInfoDetail;
exports.login = login;
exports.loginByPhone = loginByPhone;
exports.logout = logout;
exports.sendSmsCode = sendSmsCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: '/auth/token/login/',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/auth/users/me/',
    method: 'get',
    params: {
      token: token
    }
  });
}
function getInfoDetail(id, token) {
  return (0, _request.default)({
    url: '/auth/users/me/',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/auth/token/logout',
    method: 'post'
  });
}
function sendSmsCode(data) {
  return (0, _request.default)({
    url: '/common/sms/send_code/',
    method: 'post',
    data: data
  });
}
function loginByPhone(data) {
  return (0, _request.default)({
    url: '/common/login/',
    method: 'post',
    data: data
  });
}
function getCommonConfig(data) {
  return (0, _request.default)({
    url: '/common/common_config/',
    method: 'get',
    params: data
  });
}