"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qeeyou_web/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _date = _interopRequireDefault(require("@/utils/date"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _merchant = require("@/api/merchant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      loadingList: true,
      loadingStatistic: true,
      pay_status_options: [{
        value: 'UNPAY',
        label: '未付费'
      }, {
        value: 'PAY',
        label: '已付费'
      }],
      form: {
        dateFilte: ['', ''],
        register_at__gte: '',
        register_at__lte: '',
        member_name: '',
        pay_status: '',
        limit: 10,
        page: 0
      },
      heads: [],
      total: 0,
      tableKey: 0,
      card_member_count: {
        // 卡片数字 有效注册人户和有效购买人数
        register_member_count: 0,
        pay_member_count: 0
      },
      list: null // 表格内容
    };
  },

  watch: {
    form: {
      handler: function handler(newName, oldName) {
        if (this.form.dateFilte && this.form.dateFilte[0] !== '') {
          this.form.register_at__gte = (0, _date.default)(this.form.dateFilte[0], 'yyyy-MM-dd 00:00:00');
          this.form.register_at__lte = (0, _date.default)(this.form.dateFilte[1], 'yyyy-MM-dd 23:59:59');
        } else {
          this.form.register_at__gte = '';
          this.form.register_at__lte = '';
        }
      },
      deep: true
    }
  },
  created: function created() {
    this.onSubmit();
  },
  methods: {
    onSubmit: function onSubmit() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.loadingList = true;
      this.loadingStatistic = true;
      (0, _merchant.getMemberRegister)(this.form).then(function (response) {
        _this.list = response.results;
        _this.total = response.count;
        _this.heads = response.heads;
        _this.loadingList = false;
      });
      (0, _merchant.getMemberCount)(this.form).then(function (response) {
        _this.card_member_count = response;
        _this.loadingStatistic = false;
      });
    }
  }
};
exports.default = _default;