"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.regexp.replace");
var _merchant = require("@/api/merchant");
var _cookie = require("@/utils/cookie");
var _admin = require("@/api/admin");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      circleUrl: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1445680350,3299201752&fm=26&gp=0.jpg',
      recordTableVisible: false,
      ruleVisible: false,
      listLoading: true,
      isApplySettle: false,
      isAddPayeeForm: false,
      isApplyCodeForm: false,
      title: '申请结算',
      confirmSettle: false,
      tableKey: 0,
      radio: '1',
      limit_times: 0,
      used_times: 0,
      kol_state: 0,
      code: '',
      settle_records: {
        heads: [],
        list: []
      },
      settle_record: {
        apply_at: '',
        audit_at: '',
        status: '',
        apply_amount: ''
      },
      shared_result: {
        settle_money: '',
        verify_money: '',
        await_money: '',
        verify_members: '',
        verify_pay_members: '',
        verify_orders: ''
      },
      applySettleForm: {
        payee_id: null
      },
      payeeForm: {
        alipay_name: '',
        alipay_account: ''
      },
      defaultPayee: null,
      applyCodeForm: {
        code: '',
        can_use: true
      },
      payeeOptions: [],
      applySettleRules: {
        payee_id: {
          type: 'number',
          required: true,
          message: '请选择收款人',
          trigg: 'blur'
        }
      },
      applyKolRules: {
        code: {
          type: 'string',
          required: true,
          message: '请输入口令',
          trigg: 'blur'
        }
      },
      payeeRules: {
        alipay_name: {
          type: 'string',
          required: true,
          message: '支付宝姓名不能为空',
          trigg: 'blur'
        },
        alipay_account: {
          type: 'string',
          required: true,
          message: '支付宝账号不能为空',
          trigg: 'blur'
        }
      },
      works_money: '',
      token: (0, _cookie.getCookie)('token'),
      distributor_info: {
        distributor_id: undefined,
        // distributor_password: undefined,
        distributor_name: undefined,
        source_channel: undefined,
        contact_info: undefined,
        summary: undefined
      },
      referral_link_code: {
        distributor_id: undefined,
        referral_code: undefined,
        link: undefined
      },
      applyKolMessage: null
    };
  },
  watch: {
    isApplyCodeForm: function isApplyCodeForm(newValue, oldValue) {
      var _this = this;
      if (newValue) {
        this.$nextTick(function () {
          var exclusiveCommandRef = document.getElementById('exclusiveCommandRef');
          exclusiveCommandRef.addEventListener('compositionstart', function () {
            _this.applyCodeForm.code = event.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '');
          });
          exclusiveCommandRef.addEventListener('compositionend', function () {
            _this.applyCodeForm.code = event.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '');
          });
        });
      }
    }
  },
  created: function created() {
    var _this2 = this;
    this.notice();
    (0, _merchant.getDistributor)(this.token).then(function (response) {
      _this2.distributor_info = response.distributor_info;
      _this2.referral_link_code = response.referral_detail;
    });
    (0, _merchant.getSettleRecord)(this.token).then(function (response) {
      _this2.settle_record = response;
      if (!_this2.settle_record.status) {
        _this2.settle_record.status = {
          verbose: ''
        };
      }
    });
    (0, _merchant.getSharedResult)(this.token).then(function (response) {
      _this2.shared_result = response;
    });
    (0, _merchant.getWorksMoney)(this.token).then(function (response) {
      _this2.works_money = response.share_money;
    });
    (0, _merchant.getDistributorCode)(this.token).then(function (response) {
      _this2.code = response.code;
      _this2.limit_times = response.limit_times;
      _this2.used_times = response.used_times;
      _this2.kol_state = response.kol_state;
    });
    this.getPayee();
  },
  methods: {
    getPayee: function getPayee() {
      var _this3 = this;
      (0, _merchant.getDistributorPayee)(this.token).then(function (response) {
        _this3.payeeOptions = response.results;
        (0, _merchant.getDistributorDefaultPayee)(_this3.token).then(function (response) {
          console.log(response);
          _this3.applySettleForm.payee_id = response.id;
        });
      });
    },
    notice: function notice() {
      var _this4 = this;
      (0, _admin.Notice)({}).then(function (response) {
        if (response['content']) {
          _this4.$message({
            message: response['content'],
            showClose: true,
            type: 'warning'
          });
        }
      });
    },
    applySettle: function applySettle() {
      if (this.works_money === '' || this.works_money === 0 || this.works_money === null) {
        this.$message({
          type: 'warning',
          message: '没有可结算金额'
        });
        // this.isApplySettle = true
      } else this.isApplySettle = true;
    },
    addApplySettleForm: function addApplySettleForm(formName) {
      var _this5 = this;
      var check = true;
      this.$refs[formName].validate(function (valid) {
        if (!valid) {
          check = false;
        }
      });
      if (!check) {
        console.log('error submit!!');
        return false;
      }
      if (!this.confirmSettle) {
        this.confirmSettle = true;
        this.title = '请再次确认你的结算信息';
        return false;
      }
      this.confirmSettle = false;
      this.title = '申请结算';
      (0, _merchant.applySettle)(this.applySettleForm.payee_id).then(function (response) {
        _this5.$message({
          type: 'success',
          message: response.msg
        });
        (0, _merchant.getSettleRecord)(_this5.token).then(function (response) {
          _this5.settle_record = response;
          if (!_this5.settle_record.status) {
            _this5.settle_record.status = {
              verbose: ''
            };
          }
        });
        (0, _merchant.getSharedResult)(_this5.token).then(function (response) {
          _this5.shared_result = response;
        });
        (0, _merchant.getWorksMoney)(_this5.token).then(function (response) {
          _this5.works_money = response.share_money;
        });
      });
      (0, _merchant.getSettleRecord)(this.token).then(function (response) {
        _this5.settle_record = response;
        if (!_this5.settle_record.status) {
          _this5.settle_record.status = {
            verbose: ''
          };
        }
      });
      (0, _merchant.getSharedResult)(this.token).then(function (response) {
        _this5.shared_result = response;
      });
      (0, _merchant.getWorksMoney)(this.token).then(function (response) {
        _this5.works_money = response.share_money;
      });
      this.isApplySettle = false;
    },
    getSettleRecordButton: function getSettleRecordButton() {
      var _this6 = this;
      (0, _merchant.getSettleRecords)().then(function (response) {
        _this6.listLoading = false;
        _this6.settle_records.heads = response.heads;
        _this6.settle_records.list = response.results;
      });
      this.recordTableVisible = true;
    },
    onCopy: function onCopy(e) {
      this.$message.success('内容已复制到剪切板！');
    },
    onCopy1: function onCopy1(e) {
      if (this.referral_link_code.link.length <= 28) {
        this.$message.error('抱歉，复制失败！');
        return;
      }
      this.$message.success('内容已复制到剪切板！');
    },
    // 复制失败时的回调函数
    onError: function onError(e) {
      this.$message.error('抱歉，复制失败！');
    },
    checkCode: function checkCode(v) {
      var _this7 = this;
      this.applyCodeForm.code = event.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '');
      if (v.length < 1) {
        this.applyCodeForm.can_use = false;
        return;
      }
      (0, _merchant.getCheckCode)({
        code: v
      }).then(function (response) {
        _this7.applyCodeForm.can_use = response.status;
      });
    },
    applyKol: function applyKol() {
      this.applyKolMessage && this.applyKolMessage.close();
      if (this.kol_state === 1) {
        this.applyKolMessage = this.$message({
          type: 'warning',
          message: '已有申请中的专属口令，请耐心等待审核'
        });
        return;
      }
      var update_code_limit_times = this.$store.state.app.commonConfig.UPDATE_CODE_LIMIT_TIMES;
      // if (this.used_times < this.limit_times) {
      if (this.used_times < update_code_limit_times) {
        this.applyKolMessage = this.$message({
          type: 'warning',
          message: "\u6210\u529F\u63A8\u5E7F".concat(update_code_limit_times, "\u4E2A\u4EBA\u540E\u624D\u53EF\u7533\u8BF7\u4E13\u5C5E\u63A8\u5E7F\u53E3\u4EE4")
          // message: "成功推广" + this.limit_times + "个人后才可申请专属推广口令"
        });

        return;
      }
      this.isApplyCodeForm = true;
    },
    applyKolCode: function applyKolCode(formName) {
      var _this8 = this;
      if (!this.applyCodeForm.can_use) {
        this.applyKolMessage = this.$message({
          type: 'warning',
          message: '当前口令已被使用，请更换'
        });
        return;
      }
      var check = true;
      this.$refs[formName].validate(function (valid) {
        if (!valid) {
          check = false;
        }
      });
      if (!check) {
        console.log('error submit!!');
        return false;
      }
      (0, _merchant.getApplyKol)({
        code: this.applyCodeForm.code
      }).then(function (response) {
        if (response.state) {
          _this8.$message({
            type: 'success',
            message: '申请成功!'
          });
          _this8.getPayee();
        } else {
          _this8.$message({
            type: 'warning',
            message: response.msg
          });
        }
        _this8.applyCodeForm.can_use = true;
        _this8.isApplyCodeForm = false;
      }).catch(function () {
        _this8.$message({
          type: 'error',
          message: '申请失败，请重试'
        });
      });
    },
    addPayee: function addPayee(formName) {
      var _this9 = this;
      var check = true;
      this.$refs[formName].validate(function (valid) {
        if (!valid) {
          check = false;
        }
      });
      if (!check) {
        console.log('error submit!!');
        return false;
      }
      // this.payeeForm.alipay_name = this.payeeForm.alipay_name.trim();
      // this.payeeForm.alipay_account = this.payeeForm.alipay_account.trim();
      (0, _merchant.addDistributorPayee)(this.payeeForm).then(function (response) {
        _this9.$message({
          type: 'success',
          message: '添加成功!'
        });
        _this9.getPayee();
        _this9.isAddPayeeForm = false;
      }).catch(function () {
        _this9.$message({
          type: 'error',
          message: '添加失败，请重试'
        });
      });
    },
    changeRadio: function changeRadio(value) {
      this.radio = value;
    }
  }
};
exports.default = _default;